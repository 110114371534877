import { useCallback, useEffect, useRef, useState } from 'react';

import { useValueRef } from 'shared/hooks/useValueRef';
import { FilterOptions, FiltersList } from 'shared/types';
import { entriesFromObject } from 'shared/utils/objectToArray';

type State<T extends FiltersList> = { [key in keyof T]: string[] };

const createInitialState = <T extends FiltersList>(filters: T) => {
  return entriesFromObject(filters).reduce<State<T>>((acc, [key, value]) => {
    acc[key] = entriesFromObject(value.options).reduce<string[]>((optionAcc, [optionKey, optionValue]) => {
      if (optionValue.isApplied) {
        optionAcc.push(optionKey);
      }

      return optionAcc;
    }, []);

    return acc;
  }, {} as State<T>);
};

const generateAppliedFilters = <T extends FiltersList>(appliedFiltersState: State<T>, filters: T) => {
  return entriesFromObject(filters).reduce<T>((acc, [key, value]) => {
    acc[key] = {
      ...value,
      options: entriesFromObject(value.options).reduce<FilterOptions>((optionAcc, [optionKey, optionValue]) => {
        optionAcc[optionKey] = {
          ...optionValue,
          isApplied: appliedFiltersState[key].includes(optionKey),
        };

        return optionAcc;
      }, {}),
    };

    return acc;
  }, {} as T);
};

export const useFiltersState = <T extends FiltersList>(initialFilters: T, sendFilters: (data: T) => void) => {
  const [filters, setFilters] = useState(() => createInitialState(initialFilters));
  const initialFiltersRef = useValueRef<T>(initialFilters);
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const appliedFilters = generateAppliedFilters(filters, initialFiltersRef.current);
    sendFilters(appliedFilters);
  }, [filters, sendFilters, initialFiltersRef]);

  const setFilter = useCallback(<K extends keyof T>(filterName: K, filterValue: string[]) => {
    setFilters((prev) => {
      return { ...prev, [filterName]: filterValue };
    });
  }, []);

  const removeFilters = useCallback(<K extends keyof T>(filters: K[]) => {
    setFilters((prev) => {
      const removedFilters = filters.reduce<Partial<State<T>>>((acc, filterName) => {
        acc[filterName] = [];
        return acc;
      }, {});

      return { ...prev, ...removedFilters };
    });
  }, []);

  return { setFilter, removeFilters, filters };
};

import map from 'lodash/map';

import { transformRecordingVideoSources } from 'api/match/transformers/match/matchWithEpisodes';
import { RecordingByNameAPIResponse } from 'api/recording/types';
import { LinkApiResponse } from 'api/types';
import { APIRecording, RecordingEditAPI } from 'pages/recordings-list/api/types';
import { RecordingFilters } from 'pages/recordings-list/types/recording';
import { FilterOptions, FiltersList } from 'shared/types/filters/types';
import { Pagination } from 'shared/types/pagination/types';
import {
  EditFormRecording,
  PlayBackTypes,
  Recording,
  RecordingByName,
  RecordingTypes,
  TacticalAnalysis,
  VideoSourceStates,
  ViewTypes,
} from 'shared/types/recording/types';

interface RecordingsResponseData {
  filters: FiltersList;
  page: Pagination;
  recordings: Recording[];
}

interface RecordingsResponse {
  data: RecordingsResponseData;
  nextCursor: number;
}

// NOTE: mutating incoming data (filters)
const mapAnnotationTypeFilters = (filters: FiltersList): FiltersList => {
  const allAnnotationFiltersApplied =
    map(filters[RecordingFilters.ANNOTATION_TYPE].options, (option) => option.isApplied).every(Boolean) ||
    map(filters[RecordingFilters.ANNOTATION_TYPE].options, (option) => !option.isApplied).every(Boolean);

  if (allAnnotationFiltersApplied)
    Object.values(filters[RecordingFilters.ANNOTATION_TYPE].options).forEach((option) => (option.isApplied = false));

  const newAnnotationTypeFilterOptions: FilterOptions = {
    ALL: { title: 'All', isApplied: allAnnotationFiltersApplied, options: {} },
  };
  Object.entries(filters[RecordingFilters.ANNOTATION_TYPE].options).forEach(
    (option) => (newAnnotationTypeFilterOptions[option[0]] = option[1]),
  );

  filters[RecordingFilters.ANNOTATION_TYPE].options = newAnnotationTypeFilterOptions;

  return filters;
};

export const transformRecording = (r: APIRecording): Recording => {
  const isProcessingVideo =
    Boolean(r.videoSourcesStates?.length > 0) &&
    r.videoSourcesStates.every((videoSourceState) => videoSourceState.state === VideoSourceStates.STARTED);

  return {
    competitionName: r.competitionName ?? '',
    duration: r.duration,
    id: r.id,
    matchDay: r.matchday ?? '',
    name: r.name,
    type: r.type as RecordingTypes,
    isLive: r.isLive,
    date: new Date(r.date),
    extraTime: r.extraTime,
    teams: r.teams,
    hasTaggingEvents: r.hasTaggingEvents,
    tacticalAnalysis: r.tacticalAnalysis as TacticalAnalysis | null,
    videoSourcesStates: r?.videoSourcesStates
      ? r.videoSourcesStates.map((videoSourceState) => ({
          playBackType: videoSourceState.playBackType as PlayBackTypes,
          viewType: videoSourceState.viewType as ViewTypes,
          state: videoSourceState.state as VideoSourceStates,
        }))
      : [],
    isProcessingVideo,
    matchReportDownloadUrl: r.matchReportDownloadUrl,
  };
};

export const transformEditRecording = (r: RecordingEditAPI): EditFormRecording => {
  return {
    awayTeamScore: r.away_team_score,
    homeTeamScore: r.home_team_score,
    competitionName: r.competitionName ?? '',
    duration: r.duration,
    id: r.id,
    matchDay: r.matchday ?? '',
    name: r.name,
    type: r.type as RecordingTypes,
    date: new Date(r.date),
  };
};

export const transformRecordingByName = (r: RecordingByNameAPIResponse): RecordingByName => {
  return {
    awayTeamScore: r.away_team_score,
    competitionName: r.competitionName,
    duration: r.duration,
    homeTeamScore: r.home_team_score,
    id: r.id,
    matchDay: r.matchday,
    name: r.name,
    type: r.type as RecordingTypes,
    date: new Date(r.date),
    videoSources: transformRecordingVideoSources(r.videoSources),
    hasTacticalAnalysis: r.hasTacticalAnalysis,
    hasHomographies: r.hasHomographies,
  };
};

export interface RecordingsAPIResponse {
  cards: {
    links: LinkApiResponse[];
    content: APIRecording[];
    page: Pagination;
  };
  filters: FiltersList;
}

export const transformRecordings = (recording: RecordingsAPIResponse): RecordingsResponse => {
  return {
    data: {
      recordings: recording.cards.content.map(transformRecording),
      page: recording.cards.page,
      filters: mapAnnotationTypeFilters(recording.filters),
    },
    nextCursor: recording.cards.page.totalPages > recording.cards.page.number ? recording.cards.page.number + 1 : 0,
  };
};

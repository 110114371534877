import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingsFiltersList, useRecordings } from 'api/recording/useFetchRecordings';
import AnnotationTypeFilter from 'pages/recordings-list/components/recordings-list-page/filters-bar/annotation-type-filter';
import { RecordingListWithFilters } from 'pages/recordings-list/components/recordings-list-page/recording-list-with-filters';
import {
  RecordingTypesTabsAndFilters,
  selectInitialCompetitionIndex,
} from 'pages/recordings-list/components/recordings-list-page/recording-types-tabs-and-filters';
import styles from 'pages/recordings-list/components/recordings-list-page/RecordingsListPage.module.scss';
import { TabType } from 'pages/recordings-list/RecordingsListPageContainer';
import { RecordingFilters } from 'pages/recordings-list/types/recording';
import Container from 'shared/components/container';
import Pagination from 'shared/components/pagination';
import { useFilters } from 'shared/hooks/use-filters/useFilters';
import { useFiltersState } from 'shared/hooks/use-filters-state/useFiltersState';
import { FilterOptions, FiltersFromUrl } from 'shared/types/filters/types';

interface Props {
  defaultFilters: RecordingsFiltersList;
  competitionsTabs: TabType[];
}

const generateInitialFilters = (
  initialFilters: Partial<FiltersFromUrl<RecordingsFiltersList>>,
  competitionsTabs: TabType[],
  defaultFilters: RecordingsFiltersList,
): Partial<RecordingsFiltersList> => {
  if (!initialFilters.competition) {
    const initialCompetition = selectInitialCompetitionIndex(initialFilters.competition, competitionsTabs);

    initialFilters.competition = competitionsTabs[initialCompetition]?.value[0];
  }

  return Object.entries(defaultFilters).reduce<Partial<RecordingsFiltersList>>((acc, [key, value]) => {
    const defaultFilterKey = key as keyof RecordingsFiltersList;
    const urlFilterValue = initialFilters[defaultFilterKey];

    if (urlFilterValue) {
      acc[defaultFilterKey] = {
        ...value,
        options: Object.entries(value.options).reduce<FilterOptions>((optionAcc, [optionKey, optionValue]) => {
          const urlFilterValueArray = urlFilterValue.split(',');

          optionAcc[optionKey] = { ...optionValue, isApplied: false };

          if (urlFilterValueArray.includes(optionValue.title)) {
            optionAcc[optionKey].isApplied = true;
          }

          return optionAcc;
        }, {}),
      };
    } else {
      acc[defaultFilterKey] = value;
    }

    return acc;
  }, {});
};

export const RecordingsListPage = ({ defaultFilters, competitionsTabs }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { getFilters } = useFilters(defaultFilters);

  const getInitialFilters = useCallback(
    () => generateInitialFilters(getFilters(), competitionsTabs, defaultFilters),
    [competitionsTabs, defaultFilters, getFilters],
  );

  const {
    data,
    totalElements,
    setRecordingsFilters,
    recordingsFilters,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    RecordingsStateContext,
  } = useRecordings({ initialFilters: getInitialFilters });

  const { setFilter, removeFilters } = useFiltersState(recordingsFilters, setRecordingsFilters);

  return (
    <Container>
      <div className={styles['recordings-list-page']}>
        <div className={styles['container recordings-list-page__content']}>
          <Box sx={{ minHeight: 165 }}>
            <Box sx={{ marginBottom: 3 }}>
              <AnnotationTypeFilter
                defaultFilters={recordingsFilters}
                annotationFilter={
                  isEmpty(recordingsFilters[RecordingFilters.ANNOTATION_TYPE])
                    ? defaultFilters[RecordingFilters.ANNOTATION_TYPE]
                    : recordingsFilters[RecordingFilters.ANNOTATION_TYPE]
                }
                setFilter={setFilter}
              />

              {competitionsTabs.length > 0 && (
                <RecordingTypesTabsAndFilters
                  appliedFilters={recordingsFilters}
                  competitionsTabs={competitionsTabs}
                  filters={defaultFilters}
                  setFilter={setFilter}
                  removeFilters={removeFilters}
                />
              )}
            </Box>
          </Box>
          <RecordingsStateContext>
            <RecordingListWithFilters isLoading={data.length === 0 && isFetching} recordings={data} />
          </RecordingsStateContext>
          {data.length ? (
            <Pagination
              total={totalElements}
              displayed={data.length}
              getStatsText={(displayed, total) =>
                t('recordings-list:pagination.total', { displayed, total, count: total })
              }
              onShowMore={fetchNextPage}
              loading={isFetchingNextPage || Boolean(data.length && isFetching)}
            />
          ) : null}
        </div>
      </div>
    </Container>
  );
};

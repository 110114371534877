import { useInfiniteQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import React, { ReactNode, useMemo, useState } from 'react';

import { queryClient } from 'api/config';
import { useBackendApi } from 'api/hooks/useBackendApi';
import { transformRecordings } from 'api/recording/transformers';
import { recordingsListUrl } from 'api/routes';
import { fetchQueryResponse, HTTPMethod } from 'api/types';
import { RecordingsFilters } from 'pages/recordings-list/types/recording';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';
import { useFilters } from 'shared/hooks/use-filters/useFilters';
import { FiltersList } from 'shared/types/filters/types';
import { Recording } from 'shared/types/recording/types';

export const generateFetchRecordingsQueryRef = (clientId: string) => `fetchRecordings-clientId:${clientId}`;

export type RecordingsFiltersList = FiltersList<RecordingsFilters>;

export type FetchRecordingsResponse = fetchQueryResponse<Recording[]> & {
  fetchNextPage: () => void;
  totalElements: number;
  isFetchingNextPage: boolean;
  recordingsFilters: RecordingsFiltersList;
  filters: RecordingsFiltersList;
  setRecordingsFilters: (options: RecordingsFiltersList) => void;
  RecordingsStateContext: ({ children }: { children: ReactNode }) => JSX.Element;
};

interface UseRecordingsOptions {
  extraKey?: string;
  initialFilters?: (() => Partial<RecordingsFiltersList>) | Partial<RecordingsFiltersList>;
  refetchInterval?: number | false;
}

export interface useFetchRecordingsInterface {
  (options: UseRecordingsOptions): FetchRecordingsResponse;
}

export interface RecordingEndpointFilters {
  annotationType?: string;
  competition?: string;
  date?: string;
  matchday?: string;
  page: number;
  size?: number;
  sort?: string;
  teamId?: string;
  type?: string;
}

const DEFAULT_FILTERS = {
  annotationType: 'All',
};

const OVERWRITTEN_KEYS = {
  team: 'teamId',
};

const DEFAULT_REFETCH_INTERVAL = 60_000;

const RecordingsStateContext = React.createContext<{ filters: Partial<RecordingsFiltersList> } | undefined>(undefined);

const PAGE_SIZE = 12;
const SORT = 'date,desc';

const useRecordings: useFetchRecordingsInterface = ({
  extraKey = '',
  initialFilters = {},
  refetchInterval = DEFAULT_REFETCH_INTERVAL,
}) => {
  const [filters, setFilters] = useState<Partial<RecordingsFiltersList>>(initialFilters);
  const { transformFiltersForRequestAndSetSearchParamsWithoutHistory } = useFilters(filters);

  const { clientId } = useClientId();

  const fetchQueryRef = generateFetchRecordingsQueryRef(`${clientId}${extraKey}`);

  const value = { filters };

  const queryRef = useMemo(() => [fetchQueryRef, filters], [fetchQueryRef, filters]);

  const { isError, isSuccess, data, isFetching, isPending, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: queryRef,
    queryFn: ({ pageParam }) => {
      return useBackendApi(
        recordingsListUrl({
          ...transformFiltersForRequestAndSetSearchParamsWithoutHistory({
            filters,
            defaultFiltersData: DEFAULT_FILTERS,
            overwrittenKeys: OVERWRITTEN_KEYS,
          }),
          size: PAGE_SIZE,
          sort: SORT,
          page: pageParam,
        }),
        HTTPMethod.GET,
        transformRecordings,
      );
    },
    getNextPageParam: (lastPage: { nextCursor: number }) => {
      return lastPage.nextCursor;
    },
    refetchInterval,
    initialPageParam: 0,
  });

  const setQueryData = (data: any) => {
    return queryClient.setQueryData(queryRef, data);
  };

  const lastPage = data?.pages?.length ? data.pages[data.pages.length - 1] : { data: { filters: {} } };

  const pages = useMemo(() => {
    return data?.pages?.length
      ? data.pages.reduce((acc: Recording[], page: any) => {
          return acc.concat(page.data.recordings);
        }, [])
      : [];
  }, [data?.pages]);

  return {
    data: pages,
    isError,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    isPending,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters: lastPage.data.filters,
    setQueryData,
    fetchNextPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    totalElements: lastPage?.data?.page ? lastPage.data.page.totalElements : 0,
    setRecordingsFilters: setFilters,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    recordingsFilters: isEmpty(filters) ? lastPage.data.filters : filters,
    RecordingsStateContext: ({ children }: { children: ReactNode }) => (
      <RecordingsStateContext.Provider value={value}>{children}</RecordingsStateContext.Provider>
    ),
  };
};

const useRecordingsListFilters = () => {
  const context = React.useContext(RecordingsStateContext);
  if (context === undefined) {
    throw new Error('useRecordingsListFilters must be used within a RecordingsStateContext');
  }

  return context.filters;
};

export { useRecordings, useRecordingsListFilters };

import { useCallback } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

import { FiltersFromUrl, FiltersList } from 'shared/types';
import { entriesFromObject, keysFromObject } from 'shared/utils/objectToArray';

type TransformFiltersArgs<T> = {
  filters: T;
  defaultFiltersData?: Partial<FiltersFromUrl<T>>;
  overwrittenKeys?: Partial<FiltersFromUrl<T>>;
};

const sortSearchParams = <T extends FiltersList>(searchParams: Partial<FiltersFromUrl<T>>) => {
  return Object.entries(searchParams)
    .sort(([keyA], [keyB]) => {
      return keyA.localeCompare(keyB);
    })
    .reduce<Record<string, string>>((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }

      return acc;
    }, {});
};

export const useFilters = <T extends FiltersList>(filters: T) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilters = useCallback(() => {
    return keysFromObject(filters).reduce<Partial<FiltersFromUrl<T>>>((acc, key) => {
      const filter = searchParams.get(key as string);
      if (filter !== null) {
        acc[key] = filter;
      }

      return acc;
    }, {});
  }, [filters, searchParams]);

  const sortAndSetSearchParams = useCallback(
    <K extends T>(searchParams: Partial<FiltersFromUrl<K>>, options?: NavigateOptions) => {
      setSearchParams(sortSearchParams(searchParams), options);
    },
    [setSearchParams],
  );

  const transformFiltersForRequestAndSetSearchParamsWithoutHistory = useCallback(
    <K extends T>({ filters, defaultFiltersData = {}, overwrittenKeys = {} }: TransformFiltersArgs<K>) => {
      const searchParamsData: Partial<FiltersFromUrl<K>> = {};

      const appliedFilters = entriesFromObject(filters).reduce<Partial<FiltersFromUrl<K>>>((acc, [key, value]) => {
        const overwrittenKey = overwrittenKeys[key];

        const filter = entriesFromObject(value.options).reduce<string>((optionAcc, [optionKey, optionValue]) => {
          if (optionValue.isApplied) {
            optionAcc += `${optionAcc ? ',' : ''}${optionKey}`;
          }
          return optionAcc;
        }, '');

        if (filter) {
          if (overwrittenKey) {
            acc[overwrittenKey] = filter;
          } else {
            acc[key] = filter;
          }

          searchParamsData[key] = filter;
        } else if (!filter && defaultFiltersData[key]) {
          if (overwrittenKey) {
            acc[overwrittenKey] = defaultFiltersData[key];
          } else {
            acc[key] = defaultFiltersData[key];
          }

          searchParamsData[key] = defaultFiltersData[key];
        }

        return acc;
      }, {});

      sortAndSetSearchParams(searchParamsData, { replace: true });

      return appliedFilters;
    },
    [sortAndSetSearchParams],
  );

  return { getFilters, transformFiltersForRequestAndSetSearchParamsWithoutHistory };
};
